.logo-cxp {
  margin-right: 1rem;
}
.new-header {
  width: 99% !important;
  padding: 1.4rem;
}
.new-content-aside {
  width: 99% !important;
  padding: 1.4rem !important;
}
@media only screen and (max-width: 500px) {
  .logo-cxp {
    display: none;
  }
}
.card {
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.card:hover {
  opacity: 0.6;
}
.shaved-btn {
  border: none;
}
.opacity-sorting {
  opacity: 0.6;
}
